import React from 'react';

import { createRoot } from 'react-dom/client';
import {
  EventType,
  PublicClientApplication,
  AccountInfo,
  SilentRequest,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Provider as ReduxProvider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import App, { TOKEN_KEY } from './App';
import './i18n';
import store from './data/store';

import { msalConfig, tokenRequest } from './config';

const app = document.getElementById('root');

// create a root
const root = createRoot(app!);

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const { payload } = event;
    msalInstance.setActiveAccount(payload as AccountInfo);

    const account = msalInstance.getActiveAccount();

    const request: SilentRequest = {
      ...tokenRequest,
      account: account!,
    };
    try {
      // Silently acquires an access token which is then attached to a request for API access
      const response = await msalInstance.acquireTokenSilent(request);
      console.log('Fetching access token: success');
      console.log('Scopes', response.scopes);
      console.log('Token Type', response.tokenType);

      localStorage.setItem(TOKEN_KEY, response.accessToken);
    } catch (e) {
      msalInstance.acquireTokenPopup(request).then((response) => {
        localStorage.setItem(TOKEN_KEY, response.accessToken);
      });
    }
  }
});

root.render(
  <React.Suspense fallback="loading">
    <ReduxProvider store={store}>
      <MsalProvider
        instance={msalInstance}
      >
        <App />
      </MsalProvider>
    </ReduxProvider>
  </React.Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
