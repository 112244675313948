import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'counter',
  initialState: {
    theme: (localStorage) ? localStorage.getItem('theme') || 'light' : 'light',
  },
  reducers: {
    setTheme(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.theme = action.payload;
      if (localStorage) {
        localStorage.setItem('theme', state.theme);
      }
    },
  },
});

export const { setTheme } = configSlice.actions;

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

export default configSlice.reducer;
