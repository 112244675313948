import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';

export default function AuthRedirect() {
  const {
    inProgress,
  } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    console.log('redirect', inProgress);
    if (inProgress === InteractionStatus.HandleRedirect) {
      console.log('redirect handleRedirectPromise');
      /*
      msalClient.handleRedirectPromise()
        .then((res) => {
          console.log('authenticated?', res);
          if (accounts.length > 0) {
            msalClient.acquireTokenSilent({
              scopes: [process.env.REACT_APP_AZURE_AAD_SCOPE as string],
            });
          } else {
            msalClient.clearCache();
          }
          console.log('interactions caught', res);
        })
        .catch((e) => console.error(e));
        */
    }
  }, [inProgress]);

  return (
    <>
      Loading...
      {' '}
      {isAuthenticated ? 'isAuthenticated' : 'isNotAuthenticated'}
    </>
  );
}
