import { Configuration } from '@azure/msal-browser';

export const policies = {
  signin: `https://${process.env.REACT_APP_AZURE_AAD_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AAD_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_AAD_SIGNIN_POLICY}`,
  signup: `https://${process.env.REACT_APP_AZURE_AAD_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AAD_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_AAD_SIGNUP_POLICY}`,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AAD_CLIENT_ID as string,
    authority: policies.signin,
    knownAuthorities: [`${process.env.REACT_APP_AZURE_AAD_TENANT_NAME}.b2clogin.com`],
    redirectUri: process.env.REACT_APP_AZURE_AAD_REDIRECT_URL, // Replace appropriately
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {},
  /*
  {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msalLogLevel.Error:
            console.error(message);
            return;
          case msalLogLevel.Info:
            console.info(message);
            return;
          case msalLogLevel.Verbose:
            console.debug(message);
            return;
          case msalLogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
  */
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_AZURE_AAD_SCOPE as string],
};

export const tokenRequest = {
  scopes: [process.env.REACT_APP_AZURE_AAD_SCOPE as string], // Replace ... with your custom scopes
};
