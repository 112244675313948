import React from 'react';
import { useLogin, useRegister } from '@refinedev/core';
import { Layout, Button } from 'antd';

function SignInButton() {
  const { mutate: login } = useLogin();

  return (
    <Button type="primary" size="large" block onClick={() => login({})}>
      Sign in
    </Button>
  );
}

function SignUpButton() {
  const { mutate: signup } = useRegister();

  return (
    <Button type="link" size="small" block onClick={() => signup({})}>
      Sign up
    </Button>
  );
}

function LoginPage() {
  return (
    <Layout
      style={{
        background: 'radial-gradient(50% 50% at 50% 50%, #eeeeee 0%, #bbbbbb 100%)',
        backgroundSize: 'cover',
      }}
    >
      <div style={{ height: '100vh', display: 'flex' }}>
        <div style={{ maxWidth: '200px', margin: 'auto' }}>
          <div style={{ marginBottom: '28px' }}>
            <img src="./jobgether.logo.png" style={{ maxWidth: '100%' }} alt="Refine" />
          </div>
          <SignInButton />
          <SignUpButton />
        </div>
      </div>
    </Layout>
  );
}

export default LoginPage;
