import React from 'react';
import {
  Refine, AuthBindings, Authenticated,
} from '@refinedev/core';
import {
  notificationProvider, ErrorComponent, ThemedLayoutV2,
} from '@refinedev/antd';
import routerProvider, { NavigateToResource, UnsavedChangesNotifier } from '@refinedev/react-router-v6';
import {
  BrowserRouter, Outlet, Route, Routes,
} from 'react-router-dom';
import '@refinedev/antd/dist/reset.css';
import 'antd-css-utilities/utility.min.css';

import {
  Title,
  Header,
} from 'components/layout';
import { useTranslation } from 'react-i18next';
import './components/custom/styles.scss';
import { ConfigProvider, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setTheme } from 'data/config';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import axios from 'axios';
import resources from 'resources';
import AuthRedirect from 'pages/auth-redirect';
import FeathersDataProvider from './dataProviders/feathers';

import LoginPage from './pages/login';
import { loginRequest, policies, tokenRequest } from './config';

const IndexPage = React.lazy(() => import('./pages/index'));
const OfferEdit = React.lazy(() => import('./pages/offers/edit'));
const OffersList = React.lazy(() => import('./pages/offers/list'));
const CompanyEventsList = React.lazy(() => import('./pages/company-track/list'));
const CompanyEventsShow = React.lazy(() => import('./pages/company-track/show'));
const SponsorOfferEdit = React.lazy(() => import('./pages/sponsored-offers/edit'));
const CompaniesList = React.lazy(() => import('./pages/companies/list'));
const OfferModerationEdit = React.lazy(() => import('./pages/offers-moderation/edit'));
const OfferModerationShow = React.lazy(() => import('./pages/offers-moderation/show'));
const OffersModerationList = React.lazy(() => import('./pages/offers-moderation/list'));
const OfferModerationCreate = React.lazy(() => import('pages/offers-moderation/create'));
const OfferRecruitersEdit = React.lazy(() => import('./pages/offers-recruiters/edit'));
const OfferRecruitersShow = React.lazy(() => import('./pages/offers-recruiters/show'));
const OffersRecruitersList = React.lazy(() => import('./pages/offers-recruiters/list'));
const OfferRecruitersCreate = React.lazy(() => import('pages/offers-recruiters/create'));
const RawOffersList = React.lazy(() => import('./pages/raw-offers/list'));
const CompanyUsersList = React.lazy(() => import('./pages/company-users/list'));
const CompanyUsersCreate = React.lazy(() => import('./pages/company-users/create'));
const CompanyUsersShow = React.lazy(() => import('./pages/company-users/show'));

const UsersAdd = React.lazy(() => import('./pages/users/create'));
const UsersList = React.lazy(() => import('./pages/users/list'));
const UsersShow = React.lazy(() => import('./pages/users/show'));
const UsersEdit = React.lazy(() => import('./pages/users/edit'));

const ShadowAdmin = React.lazy(() => import('./pages/company-users/shadow'));
const SponsoredOffersList = React.lazy(() => import('./pages/sponsored-offers/list'));
const SponsoredMatrix = React.lazy(() => import('./pages/sponsored-offers/sponsoring-matrix'));
const SponsoredMatrixLocation = React.lazy(
  () => import('./pages/sponsored-offers/sponsoring-matrix-location'),
);
const SponsorInvoicing = React.lazy(() => import('./pages/sponsored-offers/invoicing'));
const PendingCertificationsList = React.lazy(() => import('./pages/certifications/list'));
const PriorityAccessList = React.lazy(() => import('./pages/priority-access/list'));

const MatchReviewCompany = React.lazy(() => import('./pages/match-review/company'));
const MatchReviewOffer = React.lazy(() => import('./pages/match-review/offer'));
const MatchReviewUser = React.lazy(() => import('./pages/match-review/user'));
const MatchReviewApplicants = React.lazy(() => import('./pages/match-review/applicants'));

const MatchReviewProspect = React.lazy(() => import('./pages/prospect-match/prospect'));

export const TOKEN_KEY = 'refine-auth';

interface IConfig {
  theme: 'light' | 'dark';
}

interface IAppState {
  config: IConfig;
}

export const axiosInstance = axios.create();

function App() {
  // const [currentTheme] = useState<'light' | 'dark'>('dark');
  const { t, i18n } = useTranslation();
  const config = useSelector<IAppState, IConfig>((state) => state.config);

  const dispatch = useDispatch();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { instance, inProgress, accounts } = useMsal();

  if (inProgress === 'login' || inProgress === 'handleRedirect') {
    return <div>Loading...</div>;
  }

  const account: AccountInfo = accounts[0];

  const silentRequest: SilentRequest = {
    ...tokenRequest,
    account,
  };

  axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    async (axiosReq) => {
      const silentToken = await instance.acquireTokenSilent(silentRequest);
      // eslint-disable-next-line no-param-reassign
      axiosReq.headers = {
        Authorization: `Bearer ${silentToken.accessToken}`,
        'x-jg-client': 'admin',
        ...(axiosReq.headers || {}),
      } as any;

      return axiosReq;
    },
  );

  const authProvider: AuthBindings = {
    login: async () => {
      instance.loginRedirect(); // Pick the strategy you prefer i.e. redirect or popup
      return {
        success: true,
      };
    },
    register: async () => instance.loginRedirect({
      authority: policies.signup,
      scopes: loginRequest.scopes,
      redirectUri: process.env.REACT_APP_AZURE_AAD_REDIRECT_URL,
    }).then(() => (({
      success: true,
    }))).catch((e) => {
      console.error(e.message);
      return {
        success: false,
        redirectTo: '/login',
      };
    }), // Pick the strategy you prefer i.e. redirect or popup
    updatePassword: async () => ({
      success: true,
    }),
    logout: async () => {
      await instance.logoutRedirect({
        account,
        postLogoutRedirectUri: '/login',
      }).then();
      return { success: true };
    },
    check: async () => {
      try {
        if (account && account.username.endsWith('@jobgether.com')) {
          const silentToken = await instance.acquireTokenSilent(silentRequest);
          localStorage.setItem(TOKEN_KEY, silentToken.accessToken);
          return {
            authenticated: true,
          };
        }
        return {
          authenticated: false,
          redirectTo: '/login',
        };
      } catch (e) {
        console.error(e, 'e');
        await instance.logoutRedirect({
          account,
          postLogoutRedirectUri: '/login',
        }).then();
        return {
          authenticated: false,
          redirectTo: '/login',
        };
      }
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
    getPermissions: async () => null,
    getIdentity: async (): Promise<AccountInfo | null> => {
      if (account === null || account === undefined) {
        return null;
      }
      return account;
    },
  };

  return (
    <BrowserRouter>
      <Refine
        options={{ disableTelemetry: true }}
        routerProvider={routerProvider}
        notificationProvider={notificationProvider}
        dataProvider={{
          default: FeathersDataProvider(process.env.REACT_APP_DEV_URL || '', axiosInstance),
        }}
        authProvider={authProvider}
        resources={resources}
        i18nProvider={i18nProvider}
      >
        <Routes>
          <Route
            element={(
              <Authenticated redirectOnFail>
                <ConfigProvider
                  theme={{ algorithm: config.theme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm }}
                >
                  <ThemedLayoutV2
                    Title={Title}
                    Header={() => (
                      <Header
                        setTheme={(newTheme) => {
                          dispatch(setTheme(newTheme));
                        }}
                        theme={config.theme}
                      />
                    )}
                  >
                    <Outlet />
                  </ThemedLayoutV2>
                </ConfigProvider>
              </Authenticated>
                      )}
          >
            <Route path="/">
              <Route index element={<IndexPage />} />
            </Route>
            <Route path="/company">
              <Route index element={<CompaniesList />} />
            </Route>
            <Route path="/offers-moderation">
              <Route index element={<OffersModerationList />} />
              <Route path="/offers-moderation/show/:id" index element={<OfferModerationShow />} />
              <Route path="/offers-moderation/create" index element={<OfferModerationCreate />} />
              <Route path="/offers-moderation/edit/:id" index element={<OfferModerationEdit />} />
            </Route>
            <Route path="/offers-recruiters">
              <Route index element={<OffersRecruitersList />} />
              <Route path="/offers-recruiters/show/:id" index element={<OfferRecruitersShow />} />
              <Route path="/offers-recruiters/create" index element={<OfferRecruitersCreate />} />
              <Route path="/offers-recruiters/edit/:id" index element={<OfferRecruitersEdit />} />
            </Route>
            <Route path="/offer">
              <Route index element={<OffersList />} />
              <Route path="/offer/edit/:id" index element={<OfferEdit />} />
            </Route>
            <Route path="/company-track">
              <Route index element={<CompanyEventsList />} />
              <Route path="/company-track/show/:company_id" index element={<CompanyEventsShow />} />
            </Route>
            <Route path="/sponsored-offers">
              <Route index element={<SponsoredOffersList />} />
              <Route path="/sponsored-offers/edit/:id" index element={<SponsorOfferEdit />} />
              <Route path="/sponsored-offers/jr-matrix" index element={<SponsoredMatrix />} />
              <Route path="/sponsored-offers/location-matrix" index element={<SponsoredMatrixLocation />} />
              <Route path="/sponsored-offers/invoicing" index element={<SponsorInvoicing />} />
            </Route>
            <Route path="/raw-offers">
              <Route index element={<RawOffersList />} />
            </Route>
            <Route path="/company-users/:company_id">
              <Route index element={<CompanyUsersList />} />
              <Route path="/company-users/:company_id/show/:id" element={<CompanyUsersShow />} />
              <Route path="/company-users/:company_id/create" element={<CompanyUsersCreate />} />
            </Route>
            <Route path="/users">
              <Route index element={<UsersList />} />
              <Route path="/users/show/:id" element={<UsersShow />} />
              <Route path="/users/edit/:id" element={<UsersEdit />} />
              <Route path="/users/create" element={<UsersAdd />} />
            </Route>
            <Route path="/certifications">
              <Route index element={<PendingCertificationsList />} />
            </Route>
            <Route path="/priority-access">
              <Route index element={<PriorityAccessList />} />
            </Route>
            <Route path="/match-review">
              <Route index element={<MatchReviewCompany />} />
              <Route path="/match-review/company/:companyId" element={<MatchReviewOffer />} />
              <Route path="/match-review/offer/:offerId" element={<MatchReviewUser />} />
              <Route path="/match-review/applicants/:offerId" element={<MatchReviewApplicants />} />
            </Route>
            <Route path="/prospect-match">
              <Route index element={<MatchReviewProspect />} />
              <Route path="/prospect-match/company/:companyId" element={<MatchReviewOffer />} />
              <Route path="/prospect-match/offer/:offerId" element={<MatchReviewUser />} />
            </Route>
          </Route>
          <Route
            path="/company-users/:company_id/shadow"
            element={(
              <Authenticated>
                <ConfigProvider
                  theme={{ algorithm: config.theme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm }}
                >
                  <ThemedLayoutV2
                    Title={Title}
                    Sider={() => (<>nothing here</>)}
                    Header={() => (<>nothing here</>)}
                  >
                    <ShadowAdmin />
                  </ThemedLayoutV2>
                </ConfigProvider>
              </Authenticated>
            )}
          />
          <Route
            element={(
              <Authenticated fallback={<Outlet />}>
                <NavigateToResource />
              </Authenticated>
                )}
          >
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route>
            <Route path="/auth-redirect" element={<AuthRedirect />} />
          </Route>
          <Route path="*" element={<ErrorComponent />} />
        </Routes>
        <UnsavedChangesNotifier />
      </Refine>
    </BrowserRouter>
  );
}

export default App;
